import React, {useState, useEffect} from "react";
import {
    Col, Divider,
    Input, message,
    Modal, Row,
} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {
    usePrestationUpdateAminitieMoinsMutation,
    usePrestationUpdateAminitieMutation
} from "../services/aminitie-linge-api";
import {useProductsFetchQuery} from "../../logements/services/product-api";
import {useDispatch} from "react-redux";
import {setRefresh} from "../services/planning-slice";

const ModifierKitsModalView = ({ idPrestation, petitDej, hygiene, petitDejmini, entretien, entretienmini, hygienemini, status, handleCancel}) => {
    const [kitKH, setKitKH] = useState(0);
    const [kitKPJ, setKitKPJ] = useState(0);
    const [kitKE, setKitKE] = useState(0);
    const [kitmKH, setKitmKH] = useState(0);
    const [kitmKPJ, setKitmKPJ] = useState(0);
    const [kitmKE, setKitmKE] = useState(0);
    const [updateAminitie] = usePrestationUpdateAminitieMutation();
    const [updateAminitieMoins] = usePrestationUpdateAminitieMoinsMutation();
    const products = useProductsFetchQuery();
    const dispatch = useDispatch();
    console.log(products);
    useEffect(() => {
        setKitKH(hygiene);
        setKitKPJ(petitDej);
        setKitKE(entretien);
        setKitmKH(hygienemini);
        setKitmKPJ(petitDejmini);
        setKitmKE(entretienmini);
    }, [idPrestation, status]);
    const findProductByName = (kitName) => {
        return products?.data?.find((product) => product.produit.trim() === kitName);
    };
    const handleUpdate = (nb, kitName) => {
        const dataProduct = findProductByName(kitName);
        //console.log(dataProduct);
        if(dataProduct){
            const data = {
                prix: dataProduct.prix,
                type: "amenitie",
                quantite: nb,
                prestationId: idPrestation,
                produitId: dataProduct.id,
            };
            updateAminitie(data).unwrap()
                .then(() => {
                    message.success("Information enregistrée.");
                    dispatch(setRefresh(true));
                  //  updatePlaning();
                })
                .catch((error) => {
                    message.error("Erreur lors de l'enregistrement de l'information:"+error.data.message)
                });
        }
    };
    const handleUpdateMoins = (nb, kitName) => {
        const dataProduct = findProductByName(kitName);
        //console.log(dataProduct);
        if(dataProduct){
            const data = {
                prix: dataProduct.prix,
                type: "amenitie",
                quantite: nb,
                prestationId: idPrestation,
                produitId: dataProduct.id,
            };
            updateAminitieMoins(data).unwrap()
                .then(() => {
                    message.success("Information enregistrée.");
                    dispatch(setRefresh(true));
                  //  updatePlaning();
                })
                .catch((error) => {
                    message.error("Erreur lors de l'enregistrement de l'information:"+error.data.message)
                });
        }
    };

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 175}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={300}
            className="controle-qualite"
        >

            <div className="controle">
                <div className="mintitle custom-padding">
                    Modifier les kits d'accueil
                </div>
                <div className="description-controle sans-pro-regular color-A7A7A7">
                   Kits à fournir pour cette prestation
                </div>
                <br/>
                <div className="listKit">
                    <div className="subtitle">GRAND FORMAT</div>
                    <div className="contentKit">
                        <Row>
                            <Col lg={16}>
                                <div className="label">Kit Hygiène</div>
                            </Col>
                            <Col>
                                <span className={"moins-kit " + (kitKH === 0 ? "disable-Button" : "")} onClick={kitKH === 0 ? null : () => {
                                    handleUpdateMoins(kitKH - 1, "Kit Hygiène");
                                    setKitKH(kitKH - 1);
                                }}> <MinusOutlined /> </span>
                                <div className="nombre4">
                                    <Input className="nombre-input-alter-kit" value={kitKH}
                                           onChange={(e) => null}
                                           onBlur={(e) => null} />
                                </div>
                                <span className="plus-kit"
                                      onClick={() => {
                                          handleUpdate(kitKH + 1, "Kit Hygiène");
                                          setKitKH(kitKH + 1);
                                      }}> <PlusOutlined />
                                            </span>
                            </Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col lg={16}>
                                <div className="label">Kit Petit Déjeuner</div>
                            </Col>
                            <Col>
                                <span className={"moins-kit " + (kitKPJ === 0 ? "disable-Button" : "")} onClick={kitKPJ === 0 ? null : () => {
                                    handleUpdateMoins(kitKPJ - 1, "Kit Petit Déjeuner");
                                    setKitKPJ(kitKPJ - 1);
                                }}> <MinusOutlined /> </span>
                                <div className="nombre4">
                                    <Input className="nombre-input-alter-kit" value={kitKPJ}
                                           onChange={(e) => null}
                                           onBlur={(e) => null} />
                                </div>
                                <span className="plus-kit"
                                      onClick={() => {
                                          handleUpdate(kitKPJ + 1, "Kit Petit Déjeuner");
                                          setKitKPJ(kitKPJ + 1);
                                      }}> <PlusOutlined />
                                            </span>
                            </Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col lg={16}>
                                <div className="label">Kit Entretien</div>
                            </Col>
                            <Col>
                                <span className={"moins-kit " + (kitKE === 0 ? "disable-Button" : "")} onClick={kitKE === 0 ? null : () => {
                                    handleUpdateMoins(kitKE - 1, "Kit Entretien");
                                    setKitKE(kitKE - 1);
                                }}> <MinusOutlined /> </span>
                                <div className="nombre4">
                                    <Input className="nombre-input-alter-kit" value={kitKE}
                                           onChange={(e) => null}
                                           onBlur={(e) => null} />
                                </div>
                                <span className="plus-kit"
                                      onClick={() => {
                                          handleUpdate(kitKE + 1, "Kit Entretien");
                                          setKitKE(kitKE + 1);
                                      }}> <PlusOutlined />
                                            </span>
                            </Col>
                        </Row>
                    </div>
                    <Divider/>
                    <div className="subtitle">MINI</div>
                    <div className="contentKit">
                        <Row>
                            <Col lg={16}>
                                <div className="label">Mini Kit Hygiène</div>
                            </Col>
                            <Col>
                                <span className={"moins-kit " + (kitmKH === 0 ? "disable-Button" : "")} onClick={kitmKH === 0 ? null : () => {
                                    handleUpdateMoins(kitmKH - 1, "Mini Kit Hygiène");
                                    setKitmKH(kitmKH - 1);
                                }}> <MinusOutlined /> </span>
                                <div className="nombre4">
                                    <Input className="nombre-input-alter-kit" value={kitmKH}
                                           onChange={(e) => null}
                                           onBlur={(e) => null} />
                                </div>
                                <span className="plus-kit"
                                      onClick={() => {
                                          handleUpdate(kitmKH + 1, "Mini Kit Hygiène");
                                          setKitmKH(kitmKH + 1);
                                      }}> <PlusOutlined />
                                            </span>
                            </Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col lg={16}>
                                <div className="label">Mini Kit Petit Déjeuner</div>
                            </Col>
                            <Col>
                                <span className={"moins-kit " + (kitmKPJ === 0 ? "disable-Button" : "")} onClick={kitmKPJ === 0 ? null : () => {
                                    handleUpdateMoins(kitmKPJ - 1, "Mini Kit Petit Déjeuner");
                                    setKitmKPJ(kitmKPJ - 1);
                                }}> <MinusOutlined /> </span>
                                <div className="nombre4">
                                    <Input className="nombre-input-alter-kit" value={kitmKPJ}
                                           onChange={(e) => null}
                                           onBlur={(e) => null} />
                                </div>
                                <span className="plus-kit"
                                      onClick={() => {
                                          handleUpdate(kitmKPJ + 1, "Mini Kit Petit Déjeuner");
                                          setKitmKPJ(kitmKPJ + 1);
                                      }}> <PlusOutlined />
                                            </span>
                            </Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col lg={16}>
                                <div className="label">Mini Kit Entretien</div>
                            </Col>
                            <Col>
                                <span className={"moins-kit " + (kitmKE === 0 ? "disable-Button" : "")} onClick={kitmKE === 0 ? null : () => {
                                    handleUpdateMoins(kitmKE - 1, "Mini Kit Entretien");
                                    setKitmKE(kitmKE - 1);
                                }}> <MinusOutlined /> </span>
                                <div className="nombre4">
                                    <Input className="nombre-input-alter-kit" value={kitmKE}
                                           onChange={(e) => null}
                                           onBlur={(e) => null}  />
                                </div>
                                <span className="plus-kit"
                                      onClick={() => {
                                          handleUpdate(kitmKE + 1, "Mini Kit Entretien");
                                          setKitmKE(kitmKE + 1);
                                      }}> <PlusOutlined />
                                            </span>
                            </Col>
                        </Row>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div style={{textAlign:"center"}}>
                    <a href="#" onClick={() => handleCancel()}
                       className="kit-confirmer sans-pro-regular">Confirmer</a>
                </div>
            </div>

        </Modal>
    );
};
export default ModifierKitsModalView;
