import React, { useState, useEffect } from "react";
import {Modal, Divider, Row, Col, DatePicker, Popover, Button, message} from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import moment from 'moment';
import "moment/locale/fr";
import { useEquipeMenageCreateMutation } from "../../../services/logement-api";

const EquipeMenageCreate = ({ status, handleCancel, logement }) => {
    const [mydate, setMydate] = useState("");
    const [equipeCreate] = useEquipeMenageCreateMutation();

    useEffect(() => {
        setMydate(null);
    }, [status]);


    useEffect(() => {
        const interval = setInterval(() => {
            const daysOfWeek = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
            document.querySelectorAll('.ant-picker-content th').forEach((th, index) => {
                if (index < 7) {
                    th.innerText = daysOfWeek[index];
                }
            });
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const handleChangeDate = (dateString) => {
        if (dateString === null) {
            setMydate("");
        } else {
            setMydate(dateString);
        }
    }


    const handleCreate = () => {
        if (mydate !== null) {
            const data = { logement_ids: [logement.id], start_date: moment(mydate).format('YYYY-MM-DD') }
            equipeCreate(data).unwrap()
                .then((res) => {
                    console.log("res:===>", res);
                    message.success("Equipe en cour de recherche!");
                })
                .catch((error) => {
                    console.log("error:====>", error.data.message);
                    message.error(error.data.message);
                });
        }
    }


    const contentInfo = (
        <div className="infoEquipe">
            <p>
                Date indicative à partir de laquelle <br />
                vous souhaitez que votre équipe <br />
                de ménage soit prête
            </p>
        </div>
    );

    const disabledDate = (current) => {
        return current && current < moment().add(5, 'days').startOf('day');
    };

    return (
        <Modal
            destroyOnClose
            title=""
            open={status}
            footer={null}
            closable={true}
            onCancel={() => handleCancel()}
            width={700}
            className="add-equipe-modal"
        >
            <div className="add-equipe">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/logon.png" alt="airbnb" className="title-img" /> <div className="title-label">Trouvons une équipe de ménage pour ce logement</div></div>
                <br />

                <div className="steps">
                    <div className="step1">
                        <div className="label">
                            <span>1</span>
                        </div>
                        <div className="circle">
                            <div className="circle-objet"></div>
                            <div className="line-object"></div>
                        </div>
                    </div>
                    <div className="step2">
                        <div className="label">
                            <span>2</span>
                        </div>
                        <div className="circle">
                            <div className="circle-objet"></div>
                            <div className="line-object"></div>
                        </div>
                    </div>
                    <div className="step3">
                        <div className="label">
                            <span>3</span>
                        </div>
                        <div className="circle">
                            <div className="circle-objet"></div>
                            <div className="line-object"></div>
                        </div>
                    </div>
                </div>

                <Row>
                    <Col lg={8}>
                        <div className="step-text-title sans-pro-semi-bold">Valider ce formulaire</div>
                        <div className="step-text-describe sans-pro-regular">
                            Indiquez une date de début des prestations et lancez la recherche. C’est gratuit.
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="step-text-title sans-pro-semi-bold">Trouver une équipe</div>
                        <div className="step-text-describe sans-pro-regular">
                            Nous vous préviendrons dès qu’une équipe aura été mise en place. Vous pourrez la changer si vous souhaitez.
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="step-text-title sans-pro-semi-bold">Démarrer l’entretien</div>
                        <div className="step-text-describe sans-pro-regular">
                            L’aide-ménagère effectuera toutes les prestations que vous aurez ajoutées au planning.
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col lg={8} >
                        <label htmlFor="dateSelect" className="dateSelect sans-pro-semi-bold" >Date de démarrage souhaitée</label>
                        <Popover placement="top" content={contentInfo} overlayClassName="infoContentEquipe">
                            <div className="info-equipe">
                                <img src="/assets/img/info-circled.png" alt="info" />
                            </div>
                        </Popover>
                        <DatePicker disabledDate={disabledDate} suffix={<CalendarOutlined />} id="dateSelect" className="form-control" onChange={handleChangeDate} placeholder="" />
                    </Col>
                </Row>
                <div className="footer-modal">
                    <Divider />
                    <Row>
                        <Col lg={12} className="button-left">
                            <a href="#" onClick={() => { handleCancel(); }} className="annuler"><span> Annuler </span></a>
                        </Col>
                        <Col lg={12} className="button-right">
                            <Button type="primary" onClick={() => handleCreate()}>Trouver une équipe</Button>

                            {/* <a href="#" onClick={(mydate === null || mydate === "") ? null : handleValid()}
                                className={"suivant sans-pro-regular " + ((mydate === null || mydate === "") && "disable-primary-button")}>Trouver une équipe</a> */}
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};
export default EquipeMenageCreate;
