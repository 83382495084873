import React, {useState, useEffect} from "react";
import {Input, Modal, Divider, Row, Col, Radio, Popover, AutoComplete, Spin, message} from "antd";
import {useSelector, useDispatch} from "react-redux";
import BasicCCMPage from "./explication/basic_comment_ca_marche";
import BasicMCPage from "./explication/menage_classique";
import BasicMEPPage from "./explication/menage_profondeur";
import BasicSAPPage from "./explication/service_appoint";
import DepotLingeModalView from "./modal/depot_linge";
import BasicSAPDepotPage from "./explication/service_appoint_depot";
import BasicSAPCollectePage from "./explication/service_appoint_collecte";
import BasicSAPClePage from "./explication/service_appoint_cle";
import {convertMinutes, coutMenageClassique, coutTotalMenageProfondeur} from "../../../utils/global-var";
import {useLogementFetchOne2Mutation, useLogementWithPerformanceMutation} from "../../logements/services/logement-api";
import moment from "moment";
import Calendar from 'react-calendar';
import {renameLogement} from "../../logements/utils/logement-utils";
import {usePrestationCreateMutation} from "../services/planning-api";
import {setRefresh, setSuccess} from "../services/planning-slice";
import MoyenPaiementModalView from "../../modal-global/moyen-de-paiement";
import {useGetDefaultPaymentMethodQuery} from "../../profil/services/profil-api";
import "moment/locale/fr";
import 'react-calendar/dist/Calendar.css';
import {IoCloseSharp} from "react-icons/io5";


const getGoodDate = () => {
    const now = moment();
    if (now.hour() < 12) {
        now.add(1, 'days');
    } else {
        now.add(2, 'days');
    }
    return now;
};


const ProgrammerManuellementModalView = ({logementParams, status, handleCancel, setPrestationId, prestationsNb, setValidProgram}) => {
    const defaultPaymentMethod = useGetDefaultPaymentMethodQuery();
    const [showModalDepot, setShowModalDepot] = useState(null);
    const [service, setService] = useState("");
    const [serviceAp, setServiceAp] = useState("");
    const [logements, setLogements] = useState([]);
    const [logement, setLogement] = useState([]);
    const [logementWithPerformance] = useLogementWithPerformanceMutation();
    const [logementFetchOne2] = useLogementFetchOne2Mutation();
    const [prestationCreate] = usePrestationCreateMutation();
    const [date, setDate] = useState(getGoodDate());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const {user} = useSelector((state) => state.auth);
    const [showPaieModal, setShowPaieModal] = useState(false);
    const [laundryKit, setLaundryKit] = useState(0);
    const [plan, setPlan] = useState(0);

//console.log(logementParams);

    useEffect(() => {
        if (service !== "" && service === "Service d'appoint" && (serviceAp === "" || serviceAp === "Autre besoin (forfait 1 heure)")) {
            setServiceAp("Autre besoin (forfait 1 heure)");
        } else if (service !== "Service d'appoint") {
            setServiceAp("");
        } else {
            setServiceAp(serviceAp);
        }
    }, [service]);


    useEffect(() => {
        if (logementParams) {
            setLogement(logementParams);
        }else {
            setLogement([]);
            handleSearch("");
        }
        setDate(prestationsNb === 0 ? moment().add(7, 'days') : getGoodDate());
        setService("");
        setServiceAp("");
    }, [logementParams, status]);



    const onChange = (e) => setService(e.target.value)

    const handleChangeDate = (value) => {
        const now = moment();
        const tomorrow = moment();
        tomorrow.add(1, 'days');
        const jsDate = moment(value);
        const isSameDate = moment(value).isSame(now, 'day') || moment(value).isSame(tomorrow, 'day');
        if(prestationsNb !== 0){
            if (isSameDate && now.hour() < 12) {
                jsDate.add(1, 'days');
            } else if (isSameDate && now.hour() >= 12) {
                jsDate.add(2, 'days');
            }
        }
        setDate(jsDate)
        setOpen(false);
    };


    const minDate = new Date();
    minDate.setDate(minDate.getDate() + (prestationsNb === 0 ? 7 : 2));
    const content = (<Calendar onChange={handleChangeDate} value={date} minDate={minDate}/>);

    const handleOpenChange = (newOpen) => setOpen(newOpen);

    const handleCreate = () => {
        const data = ({
            service: service,
            platform: "kliner",
            cout_menage: service === "Ménage classique" ? coutMenageClassique(logement.cout_duree, user.profil) : coutTotalMenageProfondeur(logement.cout_duree, user.profil),
            cout_kit_linge: logement.cout_duree.cout_kit_linge,
            cout_kit_accueil: logement.cout_duree.cout_kit_accueil,
            cout_kit_nettoyage: logement.cout_duree.cout_kit_nettoyage,
            cout_total: service === "Ménage classique" ? coutMenageClassique(logement.cout_duree, user.profil) : coutTotalMenageProfondeur(logement.cout_duree, user.profil),
            duree_prestation: convertMinutes(logement.cout_duree.duree_menage_classique),
            creneau_intervention_debut: `${moment(date).format('YYYY-MM-DD')}T10:00:00.000Z`,
            creneau_intervention_fin: `${moment(date).format('YYYY-MM-DD')}T16:00:00.000Z`,
            status: logement.responsable ? "Programmé" : "Programmation en cours",
            logement: logement.id,
            user: user.id,
            is_produit_menager: "",
            is_materiel_entretien: "",
            duree_prestation_min: service === "Ménage classique" ? logement?.cout_duree?.duree_menage_classique : logement?.cout_duree?.duree_menage_profondeur ,
            superficie: logement.superficie,
        })

        setLoading(true)
        prestationCreate(data)
            .unwrap()
            .then((res) => {
                if (res.status === 400) {
                    setLoading(false);
                    message.error(res.message);
                    setError(res.message);
                } else {
                    if(setPrestationId){
                        setPrestationId(res.id) ;
                    }
                    setLoading(false)
                    dispatch(setRefresh(true));
                    dispatch(setSuccess(true));
                    message.success("Prestation programmée.");
                    setValidProgram();
                    handleCancel();
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log("error:", error);
            });

    }

    const handleCreateServiceAppoint = () => {
        const data = ({
            service: "Service d'appoint",
            serviceAppointRef: serviceAp === "Dépôt de linge" ? "laundry_deposit" : "Collecte de linge" ? "laundry_collect" : "Pose d’une boite à clés" ? "keys_deposit" : "other",
            laundryKitQty: laundryKit,
            laundryKitType: plan,
            platform: "kliner",
            cout_menage: service === "Ménage classique" ? coutMenageClassique(logement.cout_duree, user.profil) : coutTotalMenageProfondeur(logement.cout_duree, user.profil),
            cout_kit_linge: logement.cout_duree.cout_kit_linge,
            cout_kit_accueil: logement.cout_duree.cout_kit_accueil,
            cout_kit_nettoyage: logement.cout_duree.cout_kit_nettoyage,
            cout_total: service === "Ménage classique" ? coutMenageClassique(logement.cout_duree, user.profil) : coutTotalMenageProfondeur(logement.cout_duree, user.profil),
            duree_prestation: convertMinutes(logement.cout_duree.duree_menage_classique),
            creneau_intervention_debut: `${moment(date).format('YYYY-MM-DD')}T10:00:00.000Z`,
            creneau_intervention_fin: `${moment(date).format('YYYY-MM-DD')}T16:00:00.000Z`,
            status: logement.responsable ? "Programmé" : "Programmation en cours",
            logement: logement.id,
            user: user.id,
            is_produit_menager: "",
            is_materiel_entretien: "",
            duree_prestation_min: service === "Ménage classique" ? logement.cout_duree.duree_menage_classique : logement.cout_duree.duree_menage_profondeur,
            superficie: logement.superficie,
        })
        setLoading(true)
        prestationCreate(data)
            .unwrap()
            .then((res) => {
                if (res.status === 400) {
                    setLoading(false);
                    setError(res.message);
                    message.error(res.message);
                } else {
                    if(setPrestationId){
                        setPrestationId(res.id) ;
                    }
                    setLoading(false)
                    dispatch(setRefresh(true));
                    dispatch(setSuccess(true));
                    setValidProgram();
                    message.success("Prestation programmée.");
                    handleCancel();
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log("error:", error);
            });

    }

    const checkPaymentMethod = () => {
        if (defaultPaymentMethod.isSuccess && defaultPaymentMethod.data) {
            //create
            if (service === "Service d'appoint") {
                handleCreateServiceAppoint()
            } else {
                handleCreate();
            }
        } else {
            setShowPaieModal(true);
        }
    }

    const handleSearch = (value) => {
        const research = value !== "" ? {search: value} : "";
        const data = ({...research, status_color: ["GREEN"]})
        logementWithPerformance(data).unwrap()
            .then((res) => {
                setLogements(() => {
                    return renameLogement(res.data.logements).map((item) => ({
                        label: `${item.logement_libelle}`,
                        value: `${item.logement_libelle}`,
                        values: `${item.logement_id}`,
                    }));
                });
            })
            .catch(() => {
            });

    };


    const onSelect = (val, option) => {
        console.log(val);
        setLoading(true)
        logementFetchOne2({id: option.values}).unwrap()
            .then((res) => {
                setLoading(false);
                setLogement(res);
            })
            .catch(() => {
                    setLoading(false);
                    setLogement([]);
                }
            );
    };

    //console.log(date);
    //console.log(logement);
    return (
        <Modal
            destroyOnClose
            open={status}
            footer={null}
            closable={true}
            onCancel={() => handleCancel()}
            width={625}
            className="programmer-manuellement">
            <Spin spinning={loading}>
                <div className="programmer-m">
                    <div className="title sans-pro-semi-bold">
                        <div className="title-label">Programmer une prestation</div>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {
                                logementParams ?
                                    <div className="researchInput">
                                        <div className="input">
                                            <div className="label" style={{paddingBottom:"3px"}}>{logementParams?.libelle}</div>
                                        </div>
                                    </div>
                                    :
                                    <AutoComplete
                                        style={{width: "100%"}}
                                        className="form-control researchInput"
                                        onChange={(val, option) => onSelect(val, option)}
                                        onSearch={handleSearch}
                                        options={logements}>
                                        {logement.length === 0 ? <Input className="form-control"
                                                                    placeholder="Où se fera la prestation (nom du logement) ?"/> :
                                            <div className="input">
                                                <div className="label">{logement?.libelle}
                                                    <div className="close" onClick={() => setLogement([])}>
                                                        <IoCloseSharp />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </AutoComplete>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24}>
                            <div className="choix-date">
                                <div className="date sans-pro-regular">Date de prestation :</div>
                                <Popover content={content} trigger="click" open={open} onOpenChange={handleOpenChange}>
                                    <div
                                        className="date-choix">{date === null ? "Choisir la date" : moment(date).format("LL")} </div>
                                </Popover>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24}>
                            <div className="select-service sans-pro-regular">
                                Sélectionner le service :
                            </div>
                        </Col>
                        <Col lg={24}>
                            <div className="contour-selection">
                                <div className="radio">
                                    <Radio.Group onChange={onChange} value={service}>
                                        <Radio className="radio-mode" value={"Ménage classique"}>
                                            <Row>
                                                <Col lg={4}>
                                                    <img
                                                        src={service === "Ménage classique" ? "./assets/img/lit-colored.png" : service === "" ? "./assets/img/lit.png" : "./assets/img/lit-grey.png"}
                                                        alt="icone" className="icone1"/>
                                                </Col>
                                                <Col lg={16}>
                                                    <div className="title sans-pro-regular">Ménage classique</div>
                                                    <div className="subtitle color-5B6E8C sans-pro-regular">Nettoyage
                                                        régulier entre deux séjours
                                                    </div>
                                                </Col>
                                                <Col lg={4} style={{textAlign:"center", fontWeight:"bold", fontSize:"16px", paddingTop:"10px"}}>
                                                    {service === "Ménage classique" && logement.length !== 0 ? (parseFloat(logement?.cout_duree?.cout_menage_classique) + parseFloat(logement?.cout_duree?.cout_kit_linge) + parseFloat(logement?.cout_duree?.cout_kit_accueil)  + parseFloat(logement?.cout_duree?.cout_produit_menager)).toFixed(2)+"€" : ""}
                                                </Col>
                                            </Row>
                                        </Radio>
                                        <Radio className="radio-mode" value={"Ménage en profondeur"}>
                                            <Row>
                                                <Col lg={4}>
                                                    <img
                                                        src={service === "Ménage en profondeur" ? "./assets/img/prestation-colored.png" : service === "" ? "./assets/img/prestation.png" : "./assets/img/prestation-grey.png"}
                                                        alt="icone" className="icone2"/>
                                                </Col>
                                                <Col lg={16}>
                                                    <div className="title sans-pro-regular">Ménage en profondeur</div>
                                                    <div className="subtitle color-5B6E8C sans-pro-regular">Après un
                                                        long séjour ou avant mise en location
                                                    </div>
                                                </Col>
                                                <Col lg={4} style={{textAlign:"center", fontWeight:"bold", fontSize:"16px", paddingTop:"10px"}}>
                                                    {service === "Ménage en profondeur" && logement.length !== 0 ? (parseFloat(logement?.cout_duree?.cout_menage_profondeur) + parseFloat(logement?.cout_duree?.cout_kit_linge) + parseFloat(logement?.cout_duree?.cout_kit_accueil)  + parseFloat(logement?.cout_duree?.cout_produit_menager)).toFixed(2)+"€" : ""}
                                                </Col>
                                            </Row>
                                        </Radio>
                                        <Radio className="radio-mode radio-mode-medium-border"
                                               value={"Service d'appoint"}>
                                            <Row>
                                                <Col lg={4}>
                                                    <img
                                                        src={service === "Service d'appoint" ? "./assets/img/hand-up-colored.png" : service === "" ? "./assets/img/hand-up.png" : "./assets/img/hand-up-grey.png"}
                                                        alt="icone" className="icone3"/>
                                                </Col>
                                                <Col lg={16}>
                                                    <div className="title sans-pro-regular">Service d’appoint</div>
                                                    <div className="subtitle color-5B6E8C sans-pro-regular">Veuillez
                                                        confirmer le type de service d’appoint ci-dessous
                                                    </div>
                                                </Col>
                                                <Col lg={4} style={{textAlign:"center", fontWeight:"bold", fontSize:"16px", paddingTop:"10px"}}>
                                                    {service === "Service d'appoint" && logement.length !== 0 ? logement?.cout_duree?.cout_menage_appoint+"€" : ""}
                                                </Col>
                                            </Row>
                                            <Divider/>
                                        </Radio>
                                    </Radio.Group>
                                    <Row>
                                        <Col className="check-zone">
                                            <div
                                                className={`choice sans-pro-regular ${serviceAp === "Dépôt de linge" && "active"}`}
                                                onClick={() => {
                                                    setService("Service d'appoint");
                                                    setShowModalDepot(true);
                                                    setServiceAp("Dépôt de linge")
                                                }}>Dépôt de linge
                                            </div>
                                            <div
                                                className={`choice sans-pro-regular ${serviceAp === "Collecte de linge" && "active"}`}
                                                onClick={() => {
                                                    setService("Service d'appoint");
                                                    setServiceAp("Collecte de linge")
                                                }}>Collecte de linge
                                            </div>
                                            <div
                                                className={`choice sans-pro-regular ${serviceAp === "Pose d’une boite à clés" && "active"}`}
                                                onClick={() => {
                                                    setService("Service d'appoint");
                                                    setServiceAp("Pose d’une boite à clés")
                                                }}>Pose d’une boite à clés
                                            </div>
                                            <div
                                                className={`choice sans-pro-regular ${serviceAp === "Autre besoin (forfait 1 heure)" && "active"}`}
                                                onClick={() => {
                                                    setService("Service d'appoint");
                                                    setServiceAp("Autre besoin (forfait 1 heure)")
                                                }}>Autre besoin (forfait 1 heure)
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <center><span style={{color: 'red'}}>{error}</span></center>
                    <Divider/>
                    <div className="title-desc">
                        {service === "" && <BasicCCMPage/>}
                        {service === "Ménage classique" && <BasicMCPage/>}
                        {service === "Ménage en profondeur" && <BasicMEPPage/>}
                        {service === "Service d'appoint" && serviceAp === "Dépôt de linge" && <BasicSAPDepotPage/>}
                        {service === "Service d'appoint" && serviceAp === "Collecte de linge" &&
                            <BasicSAPCollectePage/>}
                        {service === "Service d'appoint" && serviceAp === "Pose d’une boite à clés" && <BasicSAPClePage/>}
                        {service === "Service d'appoint" && serviceAp === "Autre besoin (forfait 1 heure)" &&
                            <BasicSAPPage/>}
                    </div>
                    <div className="footer-modal">
                        <Divider/>
                        <Row>
                            <Col lg={14} className="button-left">
                                <a href="#" onClick={() => {
                                    handleCancel();
                                }} className="annuler"><span> Annuler</span></a>
                            </Col>
                            <Col lg={10} className={"button-right "}>
                                <a href="#" onClick={() => logement.length === 0 || service === "" || (service === "Service d'appoint" && serviceAp === "") ? null : checkPaymentMethod()}
                                   className={"suivant sans-pro-regular " + (logement.length === 0 || service === "" || (service === "Service d'appoint" && serviceAp === "") ? " disable-Button" : "")}>Lancer
                                    la commande</a>
                            </Col>
                        </Row>
                    </div>
                </div>
                <DepotLingeModalView
                    status={showModalDepot}
                    handleCancel={() => setShowModalDepot(false)}
                    laundryKit={setLaundryKit}
                    plan={setPlan}
                />
                <MoyenPaiementModalView
                    status={showPaieModal}
                    handleCancel={() => setShowPaieModal(false)}
                />
            </Spin>
        </Modal>
    );
};
export default ProgrammerManuellementModalView;
